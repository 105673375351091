
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { AustralianTimeZones, Region } from '@/models/stores/regions';

interface ZonedTimer {
  value: string;
  regions: Region[];
  timeZone: string;
  start?: () => number;
  stop?: () => void;
}

@Component
export default class RegionalTimer extends LoggedInComponentBase {
  public utc8Timer: ZonedTimer = {
    value: '',
    regions: [],
    timeZone: 'Australia/Perth',
  };
  public utc95Timer: ZonedTimer = {
    value: '',
    regions: [],
    timeZone: 'Australia/Adelaide',
  };
  public utc10Timer: ZonedTimer = {
    value: '',
    regions: [],
    timeZone: 'Australia/Sydney',
  };

  private getTimerForRegion(region: Region) {
    switch (AustralianTimeZones[region]) {
      case 'utc8':
        return this.utc8Timer;
      case 'utc95':
        return this.utc95Timer;
      case 'utc10':
        return this.utc10Timer;
      default:
        return this.utc10Timer;
    }
  }

  private initZonedTimer(timer: ZonedTimer) {
    const options: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: timer.timeZone,
    };
    timer.value = Intl.DateTimeFormat('en-AU', options).format(new Date());
    timer.start = () =>
      setInterval(() => {
        timer.value = Intl.DateTimeFormat('en-AU', options).format(new Date());
      }, 60000);
    const job = timer.start();
    timer.stop = () => {
      clearInterval(job);
      timer.value = '';
    };
  }

  private initTimers(): void {
    this.storesModule.regions.forEach((region) => {
      if (region === Region.ALL) return;

      const timer = this.getTimerForRegion(region);
      if (!timer.regions.find((r) => r === region)) {
        timer.regions.push(region);
      }

      if (!timer.start) {
        this.initZonedTimer(timer);
      }
    });
  }

  private get regions(): Region[] {
    return this.storesModule.regions;
  }

  private created(): void {
    this.initTimers();
  }

  private beforeDestroy(): void {
    [this.utc10Timer, this.utc8Timer, this.utc95Timer].forEach(
      (timer) => timer.stop && timer.stop()
    );
  }

  @Watch('regions')
  private onRegionsChanged(): void {
    this.initTimers();
  }
}
