
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { StoreUser } from '@/api/contracts/storeUser';
import { UserStore } from '@/models/users/userStore';
import { Region } from '@/models/stores/regions';
import { Brand, BrandName } from '@/models/stores/brands';
import RegionalTimer from './RegionalTimer.vue';
import RefreshTokenService from '@/services/refreshTokenService';
import { findIndex, uniq } from 'lodash';

interface LinkItem {
  name: string;
  routeName: string;
  isExact?: boolean;
  isVisible: boolean;
  params?: {
    categoryId: string;
  };
}

interface StoreSelect {
  value: guid;
  text: string;
  state: Region;
  brand: Brand;
  isParticipatingInAutomatedProgram: boolean;
}

interface RegionSelect {
  text: string;
  value: Region;
}

@Component({ components: { RegionalTimer } })
export default class MainNav extends LoggedInComponentBase {
  public menu: boolean = false;
  public get links(): LinkItem[] {
    return [
      {
        name: 'Home',
        routeName: 'home.root',
        isVisible: true,
      },
      /*{
      name: 'Creative Centre',
      routeName: 'creativecentre.root'
    },*/
      {
        name: 'Calendar',
        routeName: 'calendar.root',
        isVisible: !this.$vuetify.breakpoint.xs,
      },
      {
        name: 'Asset Library + DIY',
        routeName: 'assetlibrary.root',
        params: {
          categoryId: 'all',
        },
        isVisible: true,
      },
      {
        name: 'Learn Room',
        routeName: 'helpAndFaqs.root',
        isExact: true,
        isVisible: !this.$vuetify.breakpoint.xs,
      },
      {
        name: 'FAQs',
        routeName: 'helpAndFaqs.faqs',
        isVisible: true,
      },
    ];
  }

  public storesLoading: boolean = false;
  public currentBrandIndex = 0;
  public logoKey = 0; // fix for logo not changing dimensions correctly
  public isBrandToggleMenuVisible: boolean = false;
  private wasBrandJustChanged: boolean = false;
  public isBrandIndexWatchEnabled = false; // @created this blocks changing the current store to ALL STORES

  public get isBrandToggleBtnVisible(): boolean {
    return this.availableBrands.length > 1;
  }

  public get isMSO(): boolean {
    return this.userModule.isMultiStoreOwner;
  }

  public get isViewingAllRegions(): boolean {
    return this.storesModule.currentRegion === Region.ALL;
  }

  public get isViewingSingleStore(): boolean {
    return this.userModule.isViewingSingleStore;
  }

  public get regions(): RegionSelect[] {
    const regionsFilteredBySelectedBrand = uniq(
      this.userModule
        .currentUser!.stores.filter((store) => {
          if (this.selectedBrand === null) return true;
          return this.selectedBrand.name === store.brand.name;
        })
        .map((store) => store.state)
    );

    if (regionsFilteredBySelectedBrand.length > 1) {
      regionsFilteredBySelectedBrand.push(Region.ALL);
      const region =
        regionsFilteredBySelectedBrand[
          regionsFilteredBySelectedBrand.length - 1
        ];
      if (this.wasBrandJustChanged) {
        this.wasBrandJustChanged = false;
        this.selectedRegion = {
          text: this.getRegionSelectionItemText(region!),
          value: region!,
        };
      }
    } else {
      const region = regionsFilteredBySelectedBrand[0];
      if (region !== this.selectedRegion.value) {
        this.selectedRegion = {
          text: this.getRegionSelectionItemText(region),
          value: region,
        };
      }
    }
    return regionsFilteredBySelectedBrand.map((region) => ({
      text: this.getRegionSelectionItemText(region),
      value: region,
    }));
  }

  public get selectedBrand() {
    return this.storesModule.currentBrand;
  }

  public get selectedRegion(): RegionSelect {
    return {
      text: this.getRegionSelectionItemText(this.storesModule.currentRegion),
      value: this.storesModule.currentRegion,
    };
  }

  public set selectedRegion(region: RegionSelect) {
    this.storesLoading = true;
    this.storesModule.regionChanged(region.value).then(() => {
      this.checkSocialChannelsAuthorised();
      this.storesLoading = false;
    });
  }

  public get availableBrands(): Brand[] {
    return this.storesModule.availableBrands;
  }

  public get brandFileName(): string {
    switch (this.storesModule.currentBrand.name) {
      case BrandName.LOCAL:
        return 'local-grocer-logo@2x.png';
      case BrandName.SUPA:
        return 'supa-valu-logo@2x.png';
      default:
        return 'iga-logo.svg';
    }
  }

  public get currentStore(): UserStore {
    return this.userModule.currentStore;
  }

  public get selectedStore(): StoreSelect {
    return {
      value: this.currentStore.id,
      text: this.currentStore.title,
      state: this.currentStore.state,
      brand: this.currentStore.brand,
      isParticipatingInAutomatedProgram:
        this.currentStore.isParticipatingInAutomatedProgram,
    };
  }

  public set selectedStore(store: StoreSelect) {
    this.userModule.setCurrentStore({
      id: store.value,
      title: store.text,
      state: store.state,
      brand: store.brand,
      isParticipatingInAutomatedProgram:
        store.isParticipatingInAutomatedProgram,
    });
    this.storesModule.currentStoreSet(true);
    this.checkSocialChannelsAuthorised();
  }

  public get currentUser(): StoreUser | null {
    return this.userModule.currentUser;
  }

  public get userDisplayName(): string {
    return this.currentUser?.displayName || '';
  }

  public get userStores(): Array<StoreSelect> {
    if (!this.currentUser) return [];

    /**
     * The Vuetify `select` component requires `text` and `value` properties.
     */
    return this.storesModule.userStoresInBrandAndRegion.map((store) => {
      return {
        text: store.title,
        value: store.id,
        state: store.state,
        brand: store.brand,
        isParticipatingInAutomatedProgram:
          store.isParticipatingInAutomatedProgram,
      };
    });
  }

  public logout(): void {
    RefreshTokenService.stopRefreshTokenTimeout();
    this.storesModule.reset();
    this.$router.push({ name: 'public.login' });
  }

  public onCloseBtnClick() {
    this.drawerStateChangeHandler(false);
  }

  /**
   * This will fire when
   * a) the route changes, or
   * b) the nav drawer opens or closes when user clicks on the hamburger button
   * or clicks on the background when the drawer is open which closes the drawer.
   * @param state
   */
  public drawerStateChangeHandler(state: boolean) {
    if (state !== this.uiModule.mainNavigationDrawerOpen) {
      this.uiModule.setNavigationDrawer(state);
    }
  }

  public getRegionSelectionItemText(region: Region): string {
    const storeCount = this.storesModule.regionalStoreCount.get(
      region
    ) as number;
    return `${region} - ${storeCount} ${storeCount > 1 ? 'stores' : 'store'}`;
  }

  private async created(): Promise<void> {
    this.currentBrandIndex = findIndex(this.availableBrands, (brand: Brand) => {
      return brand.name === this.storesModule.currentBrand.name;
    });

    if (!this.userModule.isMultiStoreOwner) return;

    await this.storesModule.getRegions();
    await this.storesModule.getUserStores();

    this.$nextTick(() => {
      this.isBrandIndexWatchEnabled = true;
    });
  }

  @Watch('selectedRegion')
  private onRegionSelected(newVal: RegionSelect, oldVal: RegionSelect): void {
    if (oldVal.value !== newVal.value) {
      this.storesModule.currentStoreSet(false);
      this.storesModule.regionChanged(newVal.value);
    }
  }

  @Watch('currentBrandIndex')
  private setCurrentBrand() {
    if (!this.isBrandIndexWatchEnabled) {
      return;
    }
    this.wasBrandJustChanged = true;
    this.storesModule.setCurrentBrand(
      this.availableBrands[this.currentBrandIndex]
    );
    this.storesModule.getRegions();

    // @desktop will call getUserStores via selectedRegion change
    if (this.$vuetify.breakpoint.xs) {
      this.storesModule.getUserStores();
    }

    // set store on brand change
    if (this.userStores.length > 1) {
      // 'All stores'
      this.selectedStore = this.userStores[this.userStores.length - 1];
    } else {
      this.selectedStore = this.userStores[0];
    }
  }

  @Watch('brandFileName')
  private onBrandFileNameChange(changedTo: string) {
    this.logoKey++;
  }
}
