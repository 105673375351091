
import { Component } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import MainNav from '@/components/mainNav/mainNav.vue';
import MainNavMob from '@/components/mainNav/mainNavMob.vue';
import NewAssetModal from '@/components/modals/newAssetModal.vue';
import FindAssetModal from '@/components/modals/findAssetModal.vue';
import PostModal from '@/components/modals/postModals/postModal.vue';
import MsoPostModal from '@/components/modals/postModals/msoPostModal.vue';
import FindStickersModal from '@/components/modals/findStickersModal.vue';
import AuthoriseSocialChannelsModal from '@/components/modals/authoriseSocialChannelsModal.vue';

// types
import { BrandName } from '@/models/stores/brands';

@Component({
  components: {
    FindStickersModal,
    MainNav,
    MainNavMob,
    FindAssetModal,
    NewAssetModal,
    AuthoriseSocialChannelsModal
  }
})
export default class AuthenticatedLayout extends LoggedInComponentBase {
  public get isNavigationVisible(): boolean {
    // navigation is hidden if create-post || create-asset
    return !(
      this.$route.path.includes('create-post') ||
      this.$route.path.includes('create-asset')
    );
  }

  public get postModal() {
    if (this.userModule.isViewingSingleStore) {
      return PostModal;
    }

    const editedPost = this.postModalModule.editedPost;
    return editedPost && !editedPost.isMsoPost ? PostModal : MsoPostModal;
  }

  public get progressColour(): string {
    switch (this.storesModule.currentBrand.name) {
      case BrandName.LOCAL:
        return '#c2aa72';
      default:
        return 'primary';
    }
  }
}
