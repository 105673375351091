
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { ValidationRules } from '../validationRules';
import { AssetListItem } from '@/models/assets/assetListItem';
import { PostsServiceClient } from '@/api/clients/postsServiceClient';
import { PostListItem } from '@/models/posts/postListItem';
import { States, StoreChannelsMap } from '@/models/posts/postModal';
import { PublishPostRequest } from '@/api/contracts/posts/publishPostRequest';
import { SocialChannel, SocialChannels } from '@/models/posts/socialChannels';
import { DraftPost } from '@/models/posts/draftPost';
import { EventTypesLabels } from '@/constants';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

const SubmissionTypes = {
  DRAFT: 'DRAFT',
  PUBLISH: 'PUBLISH',
};

@Component
export default class PostModal extends LoggedInComponentBase {
  protected client = new PostsServiceClient();
  protected submissionType = SubmissionTypes.DRAFT;
  public rules: ValidationRules = new ValidationRules();
  public stateVals = States;
  public unpublishChannels: Set<SocialChannel> = new Set();

  public requiredValidationError = false;
  // Custom validation values.
  public selectedAssetError = false;
  public dateTimeError = false;

  public socialChannels = SocialChannels;

  // Local values for the date/time/confirmation modals.
  public dateModal = false;
  public timeModal = false;

  // Notification
  public showNotification: boolean = false;
  public notificationMessage: string = '';
  public notificationColour: string = 'green';
  public notificationTimeout: string = '2500';

  public toggleChannel(value: boolean, channel: SocialChannel): void {
    if (
      this.state === this.stateVals.EXISTING_POST &&
      this.editedPost?.channels.includes(channel)
    ) {
      value
        ? this.unpublishChannels.delete(channel)
        : this.unpublishChannels.add(channel);
    }
  }

  public get modalTitle(): string {
    if (this.state === this.stateVals.NEW_POST) {
      return 'Create a new social post';
    } else {
      return `Edit post: ${this.postTitle}`;
    }
  }

  public get draftButtonDisplayed() {
    return (
      this.postModalModule.currentState === States.NEW_POST ||
      (this.editedPost && this.editedPost?.isDraft)
    );
  }

  public get draftButtonDisabled() {
    return (
      this.isLoading ||
      (this.editedPost && !this.editedPost?.isDraft) ||
      !this.selectedAnyChannel
    );
  }

  public get id(): guid | null {
    return this.postModalModule.id;
  }

  public get editedPost(): PostListItem | null {
    return this.postModalModule.editedPost ?? null;
  }

  public get imageUrl(): string {
    return this.selectedAsset
      ? cloudinaryImageResize(this.selectedAsset.blobUrl, 200)
      : '';
  }

  public get publishButtonDisabled() {
    return (
      this.draftButtonDisabled ||
      (this.userModule.isViewingSingleStore && this.storeChannels.length === 0)
    );
  }

  public get state(): string {
    return this.postModalModule.currentState;
  }

  public get postTitle(): string {
    return this.postModalModule.postTitle;
  }

  public set postTitle(value: string) {
    this.postModalModule.setTitle(value);
  }

  public get postChannels(): SocialChannel[] {
    return this.postModalModule.channels;
  }

  public set postChannels(value: SocialChannel[]) {
    this.postModalModule.setChannels(value);
  }

  public get immediate(): boolean {
    return this.postModalModule.immediate;
  }

  public set immediate(value: boolean) {
    this.postModalModule.setImmediate(value);
  }

  public get date(): string | null {
    return this.postModalModule.date;
  }

  public set date(value: string | null) {
    this.postModalModule.setDate(value);
  }

  public get time(): string | null {
    return this.postModalModule.time;
  }

  public set time(value: string | null) {
    this.postModalModule.setTime(value);
  }

  public get selectedAsset(): AssetListItem | null {
    return this.assetModalModule.selectedAsset;
  }

  public get selectedAssetAudiences(): string {
    if (this.selectedAsset !== null && this.selectedAsset.audiences) {
      return this.selectedAsset.audiences.map((a) => a.title).toString();
    }
    return '';
  }

  public get selectedAssetProductTypes(): string {
    if (this.selectedAsset !== null && this.selectedAsset.productTypes) {
      return this.selectedAsset.productTypes.map((a) => a.title).toString();
    }
    return '';
  }

  public get postBody(): string {
    return this.postModalModule.postBody;
  }

  public set postBody(value: string) {
    this.postModalModule.setBody(value);
  }

  public get dialog(): boolean {
    return this.postModalModule.visible;
  }

  public set dialog(value: boolean) {
    if (value === false) {
      this.resetFormValidation();
      this.postModalModule.setVisible(false);
      this.postModalModule.resetState(this.userModule.isMultiStoreOwner);
      this.assetModalModule.resetState();
    }
  }

  public get isRecommended(): boolean {
    return this.postModalModule.isRecommended;
  }

  public get usedTemplateId(): guid {
    return this.postModalModule.usedTemplateId;
  }

  public set usedTemplateId(value: guid) {
    this.postModalModule.usedTemplateId = value;
  }

  public selectAssetHandler() {
    this.assetModalModule.setAssetModalVisible(true);
  }

  public saveDraft(): void {
    this.submissionType = SubmissionTypes.DRAFT;
  }

  public get storeChannels(): SocialChannel[] {
    return this.storesModule.storeChannels;
  }

  public publishPost(): void {
    this.submissionType = SubmissionTypes.PUBLISH;
  }

  protected draftPostWithStoreChannels(
    channels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid
  ): DraftPost {
    const post: DraftPost = {
      title: this.postTitle,
      copy: this.postBody,
      channels: channels,
      storeId: storeId,
      isRecommended: false,
      asset: {
        id: this.assetModalModule.selectedAsset?.id ?? 'null',
      },
      usedTemplateId: this.usedTemplateId,
    };

    if (msoPostId) post.msoPostId = msoPostId;

    if (!this.immediate) {
      post.suggestedPublishDateTime = new Date(
        `${this.date}T${this.fixTimeAm}`
      ).toISOString();
    } else {
      post.suggestedPublishDateTime = new Date().toISOString();
    }
    return post;
  }

  public async formSubmit(
    msoPostId?: guid,
    multiStoreChannels?: StoreChannelsMap
  ) {
    // Reset time to show notification
    this.notificationTimeout = '2500';
    this.showNotification = false;
    const form = this.$refs.postForm as VForm;

    try {
      if (this.validate(form)) {
        let submitResponse;
        if (multiStoreChannels) {
          submitResponse = await Promise.allSettled(
            [...multiStoreChannels.entries()].map(
              ([
                storeId,
                { selectedChannels, unpublishChannels, isCreatingNewPost },
              ]) =>
                this.afterValidationSubmit(
                  selectedChannels,
                  unpublishChannels,
                  storeId,
                  msoPostId,
                  isCreatingNewPost
                )
            )
          );
        } else {
          const storeId =
            this.currentStore.id || this.editedPost!.msoPostStores![0].storeId;
          submitResponse = await this.afterValidationSubmit(
            this.postChannels,
            [...this.unpublishChannels.values()],
            storeId
          );
        }

        // Saving a new draft returns a post object, but updating it does not.
        const post: PostListItem = submitResponse?.data?.data;

        this.notificationMessage =
          post && post.postType === EventTypesLabels.DraftPosts
            ? 'Draft has been successfully saved'
            : 'Post has been successfully published.';
        this.notificationColour = 'success';
        this.showNotification = true;

        setTimeout(() => {
          this.dialog = false;
        }, parseInt(this.notificationTimeout));
      }

      // Dispatch update action to notify subscribers
      this.postModalModule.postUpdated();
    } catch (response) {
      // Set a default message:
      this.notificationMessage =
        'There was an error and the post has not been published.';

      // Check response for additional error messaging:
      if (response && (response as { data: any }).data) {
        const responseData = (response as { data: any }).data;
        if (responseData.displayMessage && responseData.displayMessage !== '') {
          this.notificationMessage = responseData.displayMessage;

          /**
           * There could also be a help link in the response
           * e.g provide a link for a user to re-authenticate a social account.
           */
          if (responseData.errorHelpLink && responseData.errorHelpLink !== '') {
            this.notificationMessage = `${this.notificationMessage} <p><a href='${responseData.errorHelpLink}' target='_blank'>Click here to resolve</a></p>`;
          }
        }
      }

      this.notificationTimeout = '10000';
      this.notificationColour = 'error';
      this.showNotification = true;
    }
  }

  private async submitExistingPost(
    selectedChannels: SocialChannel[],
    unpublishChannels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid
  ) {
    let result;
    const updatedPost = Object.assign({}, this.editedPost);

    updatedPost.title = this.postTitle;
    updatedPost.copy = this.postBody;
    updatedPost.channels = selectedChannels;

    if (updatedPost.asset) {
      updatedPost.asset.id = this.selectedAsset?.id ?? 'null';
    }

    if (!this.immediate) {
      updatedPost.suggestedPublishDateTime = new Date(
        `${this.date}T${this.fixTimeAm}`
      ).toISOString();
    } else {
      updatedPost.suggestedPublishDateTime = new Date().toISOString();
    }

    this.postModalModule.setEditedPost(updatedPost);

    // to change channels we need to call unpublish IMB-6
    if (unpublishChannels.length) {
      await this.client.unpublishPost(
        storeId,
        updatedPost.id,
        msoPostId,
        unpublishChannels // pass channels to remove
      );
    }

    if (selectedChannels) {
      const updatePostResponse = await this.client.updatePost(
        storeId,
        updatedPost,
        msoPostId
      );

      if (updatePostResponse) {
        const params: PublishPostRequest = {
          postId: updatePostResponse?.data.data.id,
          isDraft: this.submissionType === SubmissionTypes.DRAFT,
          channels: selectedChannels,
        };

        if (!this.immediate) {
          params.publishTime = new Date(
            `${this.date}T${this.fixTimeAm}`
          ).toISOString();
        } else {
          params.publishTime = new Date().toISOString();
        }

        if (params.channels?.length) {
          result = await this.client.publishPost(params, storeId);
        } else {
          result = updatePostResponse;
        }
      }
    }

    return result;
  }

  private async submitNewPost(
    selectedChannels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid
  ) {
    let result;
    const draftPost = this.draftPostWithStoreChannels(
      selectedChannels,
      storeId,
      msoPostId
    );

    const draftResponse = await this.postModalModule.saveDraftPost(draftPost);

    if (draftResponse) {
      const params: PublishPostRequest = {
        postId: draftResponse.data.data.id,
        isDraft: this.submissionType === SubmissionTypes.DRAFT,
        channels: selectedChannels,
      };

      if (!this.immediate) {
        params.publishTime = new Date(
          `${this.date}T${this.fixTimeAm}`
        ).toISOString();
      } else {
        params.publishTime = new Date().toISOString();
      }

      // BED: publish only when channels are selected
      if (params.channels?.length) {
        result = await this.client.publishPost(params, storeId);
      } else {
        result = draftResponse;
      }
    }

    return result;
  }

  protected async afterValidationSubmit(
    selectedChannels: SocialChannel[],
    unpublishChannels: SocialChannel[],
    storeId: guid,
    msoPostId?: guid,
    isCreatingNewPost?: boolean
  ) {
    /**
     * EXISTING_POST: An edited draft post.
     * NEW_POST: created using a recommended post or from scratch.
     */
    switch (this.postModalModule.currentState) {
      case States.EXISTING_POST:
        return isCreatingNewPost
          ? this.submitNewPost(selectedChannels, storeId, msoPostId)
          : this.submitExistingPost(
              selectedChannels,
              unpublishChannels,
              storeId,
              msoPostId
            );

      case States.NEW_POST:
        return this.submitNewPost(selectedChannels, storeId, msoPostId);
    }
  }

  public channelDisabled(channel: SocialChannel): boolean {
    const availableChannels = this.userModule.isViewingSingleStore
      ? this.storeChannels
      : this.editedPost!.msoPostStores![0].availableChannels;
    return (
      !availableChannels.includes(channel) ||
      (this.postModalModule.isRecommended &&
        !this.postModalModule.availableChannels.includes(channel))
    );
  }

  public get fixTimeAm() {
    return `${
      this.time && this.time?.length < 5 ? `0${this.time}` : this.time
    }`;
  }

  @Watch('selectedAsset')
  protected onSelectedAssetChanged(asset: AssetListItem | null) {
    if (asset?.id) {
      this.selectedAssetError = false;
    }
  }

  protected validate(form: VForm): boolean {
    const requiredValidation = form.validate();
    this.requiredValidationError = !requiredValidation;

    let customValidation = true;

    if (!this.selectedAsset?.id) {
      this.selectedAssetError = true;
      customValidation = false;
    }

    if (!this.immediate && !this.time && !this.date) {
      this.dateTimeError = true;
      customValidation = false;
    }

    return requiredValidation && customValidation;
  }

  @Watch('dialog')
  private onDialogChanged(val: boolean): void {
    if (!val) {
      this.postModalModule.resetState();
    }
  }

  protected get selectedAnyChannel(): boolean {
    return this.postChannels.length > 0;
  }

  protected resetFormValidation(): void {
    const form = this.$refs.postForm as HTMLFormElement;
    form && form.resetValidation();
    this.selectedAssetError = false;
    this.dateTimeError = false;
    this.requiredValidationError = false;
  }
}
