export class PaginationRequest {
  public total?: number = 0;

  constructor(public limit = 0, public start = 0, public sort?: string, public query?: string) {
    this.limit = limit;
    this.start = start;
    this.sort = sort;
    this.query = query;
  }
}
