
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { SortTypes } from '@/models/pagination/paginationSortEnums';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { PaginationRequest } from '@/models/pagination/paginationRequest';
import { Sticker } from '@/api/contracts/assets/sticker';
import { StickersCollection } from '@/api/contracts/assets/stickersCollection';
import { StickersRequest } from '@/models/assets/stickersRequest';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

@Component
export default class FindStickersModal extends LoggedInComponentBase {
  private client = new AssetsServiceClient();

  public stickers: Sticker[] = [];
  public page = new PaginationRequest();
  public loading: boolean = false;
  public searchString: string = '';

  public get currentStore() {
    return this.userModule.currentStore;
  }

  public get selected(): Sticker | null {
    return this.stickerModalModule.selectedSticker;
  }

  public set selected(item: Sticker | null) {
    this.stickerModalModule.setSelectedSticker(item);
  }

  public get pagination() {
    return Math.floor(this.page.start / this.page.limit) + 1;
  }

  public set pagination(value) {
    this.page.start = this.page.limit * (value - 1);
  }

  public get paginationSize() {
    return Math.ceil((this.page.total ?? 0) / this.page.limit);
  }

  public get resultsText() {
    if (this.page.start === 0 && this.stickers.length < this.page.limit) {
      return `Showing ${this.stickers.length} of ${this.stickers.length} ${
        this.stickers.length === 1 ? 'result' : 'results'
      }.`;
    } else {
      if (this.page.start + this.page.limit > (this.page.total ?? 0)) {
        return `Showing ${this.page.start} - ${this.page.total} of ${this.page.total} results.`;
      } else {
        return `Showing ${this.page.start || 1} - ${this.page.start +
          this.page.limit}  of ${this.page.total} results.`;
      }
    }
  }

  mounted() {
    // Prep for initial load.
    this.page.limit = 12;
    this.page.start = 0;
    this.page.sort = SortTypes.DatePublishedDescending;
  }

  public get dialog(): boolean {
    return this.stickerModalModule.visible;
  }

  public set dialog(value: boolean) {
    if (value === false) {
      this.stickerModalModule.setFindStickerModalVisible(false);
    }
  }

  public getImageUrl(sticker: Sticker) {
    return cloudinaryImageResize(sticker.blobUrl, 200);
  }

  @Watch('pagination')
  private async onPageChanged(page: number, oldPage: number) {
    if (page !== oldPage) {
      this.loading = true;
      const response = await this.stickersRequest();
      this.stickers = response.data;
      this.page = response.pagination;
      this.loading = false;
    }
  }

  @Watch('dialog')
  private async onDialogChanged(val: boolean) {
    if (val === true) {
      this.loading = true;
      const response = await this.stickersRequest();
      this.stickers = response.data;
      this.page = response.pagination;
      this.loading = false;
    }
  }

  public async search() {
    this.loading = true;
    this.page.start = 0;
    const response = await this.stickersRequest();
    this.stickers = response.data;
    this.page = response.pagination;
    this.loading = false;
  }

  private async stickersRequest(): Promise<StickersCollection> {
    return await this.client.getStickers(
      new StickersRequest({
        query: this.searchString,
        pagination: this.page
      })
    );
  }
}
