import { PaginationRequest } from '@/models/pagination/paginationRequest';
import { StickersRequestParams } from '@/models/assets/stickersRequestParams';

export class StickersRequest {
  query?: string = '';
  pagination: PaginationRequest = { limit: 0, start: 0, sort: '' };

  constructor(params?: StickersRequestParams) {
    Object.assign(this, params);
  }
}
