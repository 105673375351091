
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { DataTableItem } from '@/models/posts/postModal';
import { SocialChannel } from '@/models/posts/socialChannels';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

@Component
export default class StoresTable extends LoggedInComponentBase {
  @Prop({ default: [], required: true }) headers!: DataTableHeader[];
  @PropSync('items', { default: [], required: true })
  syncedItems!: DataTableItem[];

  public storeChannelToggled(
    tableItem: DataTableItem,
    channel: SocialChannel,
    value: boolean
  ): void {
    const payload = {
      tableItem,
      channel,
      value
    };
    this.$emit('storeChannelToggled', payload);
  }
}
