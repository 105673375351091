
import { Component, Watch } from 'vue-property-decorator';
import { LoggedInComponentBase } from '@/components/base/loggedInComponentBase';
import { PagedAssetListResponse } from '@/api/contracts/assets';
import { AssetListRequest } from '@/models/assets/assetListRequest';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { AssetListItem } from '@/models/assets/assetListItem';
import { PaginationRequest } from '@/models/pagination/paginationRequest';
import cloudinaryImageResize from '@/helpers/cloudinary-image-resize';

@Component
export default class FindAssetModal extends LoggedInComponentBase {
  private client = new AssetsServiceClient();

  public assets: AssetListItem[] = [];
  public page = new PaginationRequest();
  public loading: boolean = false;
  public searchString: string = '';
  public results: PagedAssetListResponse[] = [];
  public selectedAsset: AssetListItem | null = null;

  public get pagination() {
    let result = 1;
    if (this.page.limit > 0) {
      result += Math.floor(this.page.start / this.page.limit);
    }
    return result;
  }

  public set pagination(value) {
    this.page.start = this.page.limit * (value - 1);
  }

  public get paginationSize() {
    return Math.ceil((this.page.total ?? 0) / this.page.limit);
  }

  public get resultsText() {
    if (this.page.start === 0 && this.assets.length < this.page.limit) {
      return `Showing ${this.assets.length} of ${this.assets.length} ${
        this.assets.length === 1 ? 'result' : 'results'
      }.`;
    } else {
      if (this.page.start + this.page.limit > (this.page.total ?? 0)) {
        return `Showing ${this.page.start} - ${this.page.total} of ${this.page.total} results.`;
      } else {
        return `Showing ${this.page.start || 1} - ${this.page.start +
          this.page.limit}  of ${this.page.total} results.`;
      }
    }
  }

  mounted() {
    // Prep for initial load.
    this.page.limit = 12;
    this.page.start = 0;
    this.page.sort = '';
  }

  public get dialog(): boolean {
    return this.assetModalModule.visible;
  }

  public set dialog(value: boolean) {
    if (value === false) {
      this.assetModalModule.setAssetModalVisible(false);
    }
  }

  public getImageUrl(asset: AssetListItem) {
    return cloudinaryImageResize(asset.blobUrl, 200);
  }

  public onSelectBtnClick() {
    this.assetModalModule.setSelectedAsset(this.selectedAsset);
    this.closeModal();
  }

  public closeModal() {
    this.dialog = false;
    this.searchString = '';
  }

  @Watch('pagination')
  private async onPageChanged(page: number, oldPage: number) {
    if (page !== oldPage) {
      this.loading = true;
      const response = await this.assetRequest();
      this.assets = response.data;
      this.page = response.pagination;
      this.loading = false;
    }
  }

  @Watch('dialog')
  private async onDialogChanged(val: boolean) {
    if (val === true) {
      this.loading = true;
      const response = await this.assetRequest();
      this.assets = response.data;
      this.page = response.pagination;
      this.loading = false;
    }
  }

  public async search() {
    this.loading = true;
    this.page.start = 0;
    const response = await this.assetRequest();
    this.assets = response.data;
    this.page = response.pagination;
    this.loading = false;
  }

  private async assetRequest(): Promise<PagedAssetListResponse> {
    return await this.client.getAssets(
      new AssetListRequest({
        includeRecommended: true,
        query: this.searchString,
        pagination: this.page,
        state: this.storesModule.currentRegion
      })
    );
  }
}
